





import {Component, Prop, Vue} from "vue-property-decorator";
import SpecAttachmentEntity, {SpecAttachmentType} from "@/entities/specs/spec-attachment-entity";
import SpecEntity, {SpecBaseEntity} from "@/entities/specs/spec-entity";

@Component({
})
export default class InputAttachment extends Vue {
  @Prop({required: true}) private spec!:SpecBaseEntity;
  @Prop({required: true}) private attachmentKey!:string;
  @Prop({type: Boolean, required: true}) private isPackageImage!:boolean;

  private file:SpecAttachmentEntity = new SpecAttachmentEntity({
    type: this.isPackageImage ? SpecAttachmentType.PackageImage : SpecAttachmentType.Document,
    key: this.attachmentKey
  });

  private find(s:SpecAttachmentEntity) {
    return s.key === this.attachmentKey;
  }
  private created() {
    const existing = this.spec.attachments.find(this.find);
    if (existing) {
      this.file = existing;
    }
  }

  private onInput() {
    const index = this.spec.attachments.findIndex(this.find);
    if (index !== -1) {
      this.spec.attachments.splice(index, 1);
    }
    this.spec.attachments.push(this.file);
  }
  private onRemove() {
    const idx = this.spec.attachments.findIndex(this.find);
    this.spec.attachments.splice(idx, 1);
    this.file.clear();
  }

  private get allowPdf() {
    return !this.isPackageImage;
  }

}
