


































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {
  ValidatorRules,
  SpecManufactureProcessInputType,
  SpecCreateBaseEntity,
} from "@/entities/specs/spec-entity";

import {AllergenList} from "@/entities/allergy-entity";
import SpecQualityPreservationMethodEntity, {QualityPreservationMethodTypes} from "@/entities/specs/spec-quality-preservation-method-entity";

import { help as PopoverText } from '@/lang/help/spec-create';

import AddDeleteTable from "@/components/Table/AddDeleteTable.vue";
import InspectionTable from "./components/InspectionTable.vue";
import ManufactureProcessTable from "./components/ManufactureProcessTable.vue";
import {SpecInspectionType} from "@/entities/specs/spec-inspection-entity";
import InputAttachment from "@/views/spec/companies/components/spec-components/create/components/InputAttachment.vue";
import {
  SpecDocumentAttachmentPreparedType,
  SpecManufactureAttachmentPreparedType
} from "@/entities/specs/spec-attachment-entity";
import CustomValueCreateSection from "@/views/spec/companies/components/spec-components/create/components/CustomValueCreateSection.vue";
import { SpecCustomValueCategory } from '@/entities/specs/spec-custom-value-entity';
import XRayDetectionTable from "@/views/spec/companies/components/spec-components/create/components/XRayDetectionTable.vue";
import BooleanSelect from "@/components/Form/BooleanSelect.vue";
import MultipleAttachmentSelect from "@/views/spec/companies/components/spec-components/create/components/MultipleAttachmentSelect.vue";
import CompanySpecCustomValueSettingEntity from "@/entities/company-spec-custom-value-setting-entity";

@Component({
  components: {
    MultipleAttachmentSelect,
    BooleanSelect,
    XRayDetectionTable,
    InspectionTable,
    AddDeleteTable,
    InputAttachment,
    ManufactureProcessTable,
    CustomValueCreateSection,
  },
})
export default class Manufacture extends Vue {
  @Prop({required: true}) private model!:SpecCreateBaseEntity;
  @Prop({required: false}) private customValueSettings?:CompanySpecCustomValueSettingEntity[];

  private readonly rules = ValidatorRules;
  private readonly AllergenList = AllergenList;
  private readonly SpecManufactureProcessInputType = SpecManufactureProcessInputType;
  private readonly QualityPreservationMethodTypes = QualityPreservationMethodTypes;
  private readonly SpecInspectionType = SpecInspectionType;
  private readonly PopoverText = PopoverText;
  private readonly SpecDocumentAttachmentPreparedType = SpecDocumentAttachmentPreparedType;
  private readonly SpecCustomValueCategory = SpecCustomValueCategory;
  private readonly SpecManufactureAttachmentPreparedType = SpecManufactureAttachmentPreparedType;

  private created() {
    if(!this.model.qualityPreservationMethods!.length) {
      this.addQualityPreservationMethodRow();
    }
    this.$emit('initialized');
  }

  private addQualityPreservationMethodRow() {
    this.model.qualityPreservationMethods!.push(new SpecQualityPreservationMethodEntity());
  }
}
