













































































































































import {Vue, Component, Prop, Inject} from 'vue-property-decorator';
import SpecEntity, {
  FoodTypeDict, ExpirationFormatTypeMap, ExpirationTypes,
  StorageTemperatureRangeTypeMap, ExpirationDateUnitDict, FoodType
} from "@/entities/specs/spec-entity";
import {getAlcoholLabelByCode} from '@/repositories/spec/alcohol-repository';

import ShowBase from "@/views/spec/companies/components/spec-components/show/ShowBase";
import CustomValueShowSection from "@/views/spec/companies/components/spec-components/show/components/CustomValueShowSection.vue";
import { SpecCustomValueCategory } from '@/entities/specs/spec-custom-value-entity';

@Component({
  computed: {
    ExpirationDateUnitDict() {
      return ExpirationDateUnitDict
    }
  },
  components: {
    CustomValueShowSection,
  }
})
export default class extends ShowBase {
  private readonly ExpirationDateUnitDict = ExpirationDateUnitDict;
  private readonly FoodType = FoodType;
  private readonly FoodTypeDict = FoodTypeDict;
  private readonly ExpirationFormatTypeMap = ExpirationFormatTypeMap;
  private readonly ExpirationTypes = ExpirationTypes;
  private readonly StorageTemperatureRangeTypeMap = StorageTemperatureRangeTypeMap;
  private readonly SpecCustomValueCategory = SpecCustomValueCategory;
  private getAlcoholLabelByCode = getAlcoholLabelByCode;
}
